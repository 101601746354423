import Vue from 'vue'
import App from './App.vue'
import './css/main.scss'
import i18n from './i18n'
import * as VueGoogleMaps from 'vue2-google-maps'
import 'url-search-params-polyfill';

Vue.use(VueGoogleMaps, {
  load: {
    language: i18n.locale,
    key: process.env.VUE_APP_GOOGLE_API_KEY,
    libraries: 'places',
  },
})

Vue.config.productionTip = false

new Vue({
  i18n,
  render: h => h(App)
}).$mount('#app')
