<template>
  <GmapMap
    ref="gmap"
    v-bind="{options,center,zoom}"
    class="w-full h-full"
  >
    <template v-if="google">
      <GmapMarker
        v-for="location in locations"
        :key="location.id"
        :position="{lat:location.latitude, lng:location.longitude}"
        :clickable="true"
        :icon="{
          path: 'M255,0C155.55,0,76.5,79.05,76.5,178.5C76.5,311.1,255,510,255,510s178.5-198.9,178.5-331.5C433.5,79.05,354.45,0,255,0z M255,242.25c-35.7,0-63.75-28.05-63.75-63.75s28.05-63.75,63.75-63.75s63.75,28.05,63.75,63.75S290.7,242.25,255,242.25z',
          fillColor: selected === location.id ? '#000f' : '#0004',
          fillOpacity: 1,
          anchor: new google.maps.Point(250,500),
          strokeWeight: 0,
          scale: .05
        }"
        @click="$emit('select',location.id)"
      />
    </template>
  </GmapMap>
</template>

<script>
import {gmapApi as google} from 'vue2-google-maps'

export default {
  props: {
    locations: {
      type: Array,
      required: true
    },
    selected: Number
  },
  watch: {
    selected(id) {
      const location = this.locations.find(l => l.id === id)
      // this.center = {lat: location.latitude, lng: location.longitude}
      this.$refs.gmap.$mapPromise.then((map) => {
        map.panTo(new this.google.maps.LatLng(location.latitude, location.longitude))
      })
    }
  },
  data() {
    return {
      center: {lat:0,lng:0},
      zoom: 12,
      options: {
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        disableDefaultUi: true,
        styles:[
          {
            featureType: 'poi',
            stylers: [
              { visibility: 'off' }
            ]
          }
        ]
      }
    }
  },
  computed: {
    google
  },
  mounted() {
    this.$refs.gmap.$mapPromise.then((map) => {
      const bounds  = new this.google.maps.LatLngBounds();
  
      this.locations.forEach(({latitude,longitude}) => {
        bounds.extend(new this.google.maps.LatLng(latitude, longitude))
      })
      map.fitBounds(bounds)
      map.panToBounds(bounds)
    })
  },
  methods:  {
    click($event) {
      console.log($event)
    }
  }
}
</script>