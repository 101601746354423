<template>
  <div class="mx-4 py-4 border-gray-200 border-b last:border-b-0 flex" :class="{'cursor-pointer': !isSelected}" @click="$emit('select',location.id)">
    <div v-if="!readonly" class="flex-shrink mr-3 ">
      <input type="radio" :checked="isSelected" @change="$emit('select',location.id)" :value="location.id" class="text-black">
    </div>
    <div class="flex-grow ">
      <h2 class="font-bold" :class="{'underline': isSelected}">{{location.address.company}}</h2>
      <div class="text-gray-500">
        <p class="text-sm">{{location.address.street}}, {{location.address.locality}}</p>
        <p v-if="location.address.description" class="text-sm">{{location.address.description}}</p>
        <p class="mt-2 text-xs">{{$t(`select.class.${location.class}`)}}</p>
        <p v-if="location.hours" class="mt-2 text-xs">{{$t(`select.hours`)}}: {{location.hours}}</p>
      </div>
    </div>
  </div>  
</template>

<script>
export default {
  props: {
    location: {
      type: Object,
      required: true
    },
    selected: Number,
    readonly: Boolean,
  },
  computed: {
    isSelected() {
      return this.selected === this.location.id
    }
  }
}
</script>