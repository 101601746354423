<template>
  <div class="backdrop" @click="close">
    <div class="modal" @click.stop v-if="framed">
      <div class="p-4">
        <img class="float-right py-1.5 cursor-pointer" :src="closeButton" :alt="$t('ui.close')" @click="close">
        <h1 class="text-xl">{{options.readonly ? $t('select.readonly-title') : $t('select.title')}}</h1>
      </div>
      <Spinner v-if="loading" class=" border-gray-200 border-t" />
      <div v-else-if="error" class="p-4 text-red-500">
        {{error}}
      </div>
      <div class="content justify-center" v-else>
        <template v-if="!locations.length">
          <p class="p-4 m-auto text-gray-700">{{$t('select.no-results')}}</p>
        </template>
        <template v-else>
          <div class="map">
            <Map v-bind="{locations, selected}" @select="select($event,true)" />
          </div>
          <div class="locations border-gray-200 border-t">
            <div class="location-list">
              <Location 
                :ref="`location-${location.id}`"
                v-for="location in locations" 
                :key="location.id" 
                v-bind="{location, selected}"
                :readonly="options.readonly"
                @select="select($event)"
              />
            </div>
            <div v-if="!options.readonly" class="select border-gray-200 border-t p-4">
              <Button :disabled="!selected" @click="confirm">{{$t('select.button')}}</Button>
            </div>          
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>

import { parseParams, respond } from './utils'
import closeButton from './assets/close-button.svg'
import Spinner from './components/Spinner.vue'
import Map from './components/Map.vue'
import Location from './components/Location.vue'
import Button from './components/Button.vue'
import api from './api'

export default {
  name: 'App',
  components: {
    Spinner,
    Location,
    Map,
    Button
  },
  data() {
    return {
      loading: true,
      error: null,
      locations: [],
      selected: null,
      closeButton,
      token: null,
      center: {lat:0, lng:0}
    }
  },
  created() {
    let {token,options} = parseParams()

    this.token = token
    this.options       = options

    this.validate() && this.fetch()
  },
  computed: {
    framed() {
        try {
            return window.self !== window.top;
        } catch (e) {
            return true;
        }
    }
  },
  methods: {
    close() {
      respond('close')
    },
    confirm() {
      let location = this.locations.find(l => l.id === this.selected)
      respond('select',location)
    },
    select(id,scroll = false) {
      this.selected = id
      if(scroll) {
        const ref = this.$refs[`location-${id}`]?.[0]

        ref && ref.$el.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        })
      }
    },
    validate() {
      if(!this.framed) {
        console.error('Not framed')
        return false
      }

      if(!this.token) {
        this.error = 'No merchant token configured.'
        this.loading = false

        return false
      }

      return true
    },
    async fetch() {
      try {
        const {data} = await api.get('location-selector',{
          params: {
            token: this.token,
            ...this.options
          }
        })

        this.locations = data.data

        if(this.options.selected)  {
          let id = parseInt(this.options.selected)
          if(this.locations.some(l => l.id === id)) {
            setTimeout(() => this.select(id,true),100)
          }
        }
        
      } catch (error) {
        if(error.response?.status === 404) {
          this.error = 'Merchant not found.'
        } else if(error.response?.status === 400) {
          this.error = error.response.data?.message
        } else {
          this.error = error.message || 'An error occured.'
        }
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
