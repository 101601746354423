export const respond = (action,data = null) => {
  window.parent.postMessage({action,data},'*')
}

const convertBooleans = (v) => {
  if(v === 'false' || v === '0') return false
  if(v === 'true' || v === '1') return true
  return v
}

export const parseParams = () => {
  const options = {}
  const params = new URLSearchParams(document.location.search.substring(1));

  const token = params.get('token')
  params.delete('token')

  for(let [key,value] of params.entries()) {
    options[key] = convertBooleans(value)
  }

  return {token,options}
}
