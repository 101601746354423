var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('GmapMap',_vm._b({ref:"gmap",staticClass:"w-full h-full"},'GmapMap',{options: _vm.options,center: _vm.center,zoom: _vm.zoom},false),[(_vm.google)?_vm._l((_vm.locations),function(location){return _c('GmapMarker',{key:location.id,attrs:{"position":{lat:location.latitude, lng:location.longitude},"clickable":true,"icon":{
        path: 'M255,0C155.55,0,76.5,79.05,76.5,178.5C76.5,311.1,255,510,255,510s178.5-198.9,178.5-331.5C433.5,79.05,354.45,0,255,0z M255,242.25c-35.7,0-63.75-28.05-63.75-63.75s28.05-63.75,63.75-63.75s63.75,28.05,63.75,63.75S290.7,242.25,255,242.25z',
        fillColor: _vm.selected === location.id ? '#000f' : '#0004',
        fillOpacity: 1,
        anchor: new _vm.google.maps.Point(250,500),
        strokeWeight: 0,
        scale: .05
      }},on:{"click":function($event){return _vm.$emit('select',location.id)}}})}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }