import axios from 'axios';

const api = axios.create({
  baseURL: `${process.env.VUE_APP_API_HOST}api/`,
});

api.interceptors.request.use(config => {
  if(config.params) {
    for(let [k,v] of Object.entries(config.params)) {
      if (typeof v == "boolean") {
        config.params[k] = v ? 1 : 0
      }
    }
  }

  return config
})

export default api